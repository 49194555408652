<template>
  <tr>
    <th scope="row">
      <span class="table-hash" style="min-width: 32px; display: block">{{ index + 1 }}</span>
      <div class="table-loadind spinner-border" role="status">
        <span class="sr-only"></span>
      </div>  
    </th>
    <td><span style="min-width: 54px;" v-if="espaco.id != undefined"><a :href="getUrl+'wp-admin/post.php?post='+espaco.id+'&action=edit'" class="" target="_blank">{{ espaco.id }}</a></span></td>
    <td><span v-if="espaco.tipo != undefined">{{ espaco.tipo }}</span></td>
    <td><span style="min-width: 120px;" v-if="espaco.nome != undefined"><a :href="espaco.permalink" target="_blank">{{ espaco.nome }}</a></span></td>
    <td><span style="min-width: 80px;" v-if="espaco.bairro != undefined">{{ espaco.bairro }}</span></td>
    <td><span style="min-width: 80px;" v-if="espaco.cidade != undefined">{{ espaco.cidade }}</span></td>
    <td><span style="min-width: 30px;" v-if="espaco.estado != undefined">{{ espaco.estado }}</span></td>
    <td><span v-if="espaco.contato != undefined">{{ espaco.contato }}</span></td>
    <td class="col-mail"><span v-if="espaco.mail != undefined">{{ espaco.mail }}</span></td>
    <td class=""><span style="min-width: 130px;" v-if="espaco.telefone != undefined">{{ espaco.telefone }}</span></td>
    <td><span style="min-width: 50px;" v-if="espaco.site != undefined && espaco.site !='http://'"><a :href="espaco.site" target="_blank">Site</a></span></td>
    <td><span v-if="espaco.plano != undefined"><span class="label" :class="espaco.plano">{{ espaco.plano }}</span></span></td>
    <td><span style="min-width: 27px;" v-if="espaco.features.fulltime != undefined">{{ espaco.features.fulltime }}</span></td>
    <td><span style="min-width: 27px;" v-if="espaco.features.wheelchair != undefined">{{ espaco.features.wheelchair }}</span></td>
    <td><span style="min-width: 27px;" v-if="espaco.features.privateParking != undefined">{{ espaco.features.privateParking }}</span></td>
  </tr>
</template>



<script>
import { mapGetters } from "vuex";

export default {
  props: {
    espaco: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  computed: {
    ...mapGetters(["getUrl"]),
  },
  // methods: {
  //   ...mapActions(["editDataCliente", "editDataClienteConcluir"]),
  // }
};
</script>

<style scoped>

  td span{
    display: block;
  }

  .col-mail{
    max-width: 200px;
    word-break: break-word;
  }

  .cliente-loading{
    opacity: 0.3;
    pointer-events: none;
  }
  .table-loadind{
    height: 20px;
    width: 20px;
    font-size: 12px;
    display: none;
  }
  .cliente-loading .table-loadind{
    display: inline-block;
  }
  .cliente-loading .table-hash{
    display: none;
  }

  .label{
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 8px;
    display: inline-block;
    font-weight: 400;
    background-color: #BBC3C9;
  }
  
  .administrator{
    background-color: #BBC3C9;
  }
  .coworking_free{
    background-color: transparent;
    border: 1px solid #333;
    color: #333;
  }
  .espaco_cancelado{
    background-color: #e74c3c;
  }
  .espaco_open_list{
    background-color: #999;
  }
  .espaco_tradicional{
    background-color: #3498DB;
  }
  .espaco_destaque{
    background-color: #745EC5;
  }
  .coworking_destaque{
    background-color: transparent;
    border: 1px solid #745EC5;
    color: #745EC5;
  }
  .espaco_destaque_promo{
    background-color: #FF9673;
  }
  .espaco_tradicional_v2{
    background-color: #0098DB;
  }
  .gerente{
    background-color: #BBC3C9;
  }
  .author{
    background-color: #BBC3C9;
  }
  .content_manager{
    background-color: #BBC3C9;
  }

  .tag{
    display: inline-block;
    background: #999;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
  }
  .tag.STR{
    background: #651FFF;
  }
  .tag.IND{
    background: #999;
  }
  .tag.BOL{
    background: #795548;
  }
  .tag.PAY{
    background: #2979FF;
  }

</style>