<template>
  <div class="container-fluid">

    <section class="row">
      <div class="col">
        <h1>Espaços <i class="bi bi-pencil"></i></h1>
      </div>
    </section>


    <div class="d-flex justify-content-between mt-4 mb-4 filtro-box-group">

      <form action="/espacos" method="get" class="d-flex align-items-start flex-shrink-0 flex-grow-0 space-query" id="space-query">

        <input type="hidden" name="features" id="features" v-model="this.features">

        <div class="filtro-box">
          <span class="filtro-label">Tipo: </span>
          <select v-model="espacoFiltros.tipo" name="type-value-mode" id="type-value-mode">
                <option value="">Todos</option>
                <option value="work-shared">Estação de Trabalho</option>
                <option value="work-private">Sala Privada</option>
                <option value="meeting">Sala para reuniões</option>
                <option value="event">Espaço para eventos</option>
                <option value="virtual_office">Escritório Virtual</option>
          </select>
        </div>
      
        <div class="filtro-box">
          <span class="filtro-label">Quantidade: </span>
          <select v-model="espacoFiltros.quantidade" name="select-number" id="select-number">
                <option value="">Todos</option>
                <option value="1">Somente eu</option>
                <option value="2">2 pessoas</option>
                <option value="3">3 pessoas</option>
                <option value="4">4 pessoas</option>
                <option value="5">5 pessoas</option>
                <option value="6">6 pessoas</option>
                <option value="7">7 pessoas</option>
                <option value="8">8 pessoas</option>
                <option value="9">9 pessoas</option>
                <option value="10">10 pessoas</option>
                <option value="11">Mais de 11</option>
          </select>
        </div>

        <div class="filtro-box">
          <span class="filtro-label">Cidade: </span>
          <input v-model="espacoFiltros.cidade" class="" value="" type="text" name="city" id="city" placeholder="Qual sua cidade?">
        </div>

        <input ref="myBtn" class="btn btn-primary btn-sm space-query-link" type="submit" value="Buscar">


      </form>

      <div class="flex-shrink-1 flex-grow-1 ml-1 filter-box-d">

        <div class="region-spaces-filters" id="region-spaces-filters">
            <span class="region-spaces-filters-prymary">
              Filtrar:
              <a class="region-spaces-filter" filter-name="fulltime" filter-type="primary"><i class="fas fa-check"></i><i class="fas fa-square"></i> 24 horas</a>
              <a class="region-spaces-filter" filter-name="meeting" filter-type="primary"><i class="fas fa-check"></i><i class="fas fa-square"></i> Sala de Reuniões</a>
              <a class="region-spaces-filter" filter-name="wheelchair" filter-type="primary"><i class="fas fa-check"></i><i class="fas fa-square"></i> Acessível para cadeirante</a>
              <a class="region-spaces-filter" filter-name="bike" filter-type="primary"><i class="fas fa-check"></i><i class="fas fa-square"></i> Bicicletário</a>
              <a class="region-spaces-filter region-spaces-filter-xl" filter-name="kitchen" filter-type="primary"><i class="fas fa-check"></i> Cozinha/Copa</a>
            </span>
            <span v-if="todosSpaces_.length != 0" class="region-spaces-filters-alt"><span>{{todosSpaces_.length}}</span> espaços</span><span class="region-spaces-filters-alt" id="region-spaces-filters-alt"></span>
            <div class="region-spaces-filter-add" id="region-spaces-filter-add">
              <span id="filter-add-box-label" class="filter-add-box-label">Mais filtros</span>
              <span id="filter-add-box-label-mobile" class="filter-add-box-label-mobile">Filtrar espaços</span>
              <div class="filter-add-box" id="filter-add-box">
                <div class="filter-add-box-mark"></div>
                <a class="filter-add-box-close" id="filter-add-box-close"><i class="fas fa-times"></i></a>
                <h3 class="filter-add-box-title">Filtros</h3>
                <div class="filter-add-box-list">
                  <div class="filter-add-box-list-item" filter-name="book" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Biblioteca</div>
                  <div class="filter-add-box-list-item" filter-name="loker" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Armário privado</div>
                  <div class="filter-add-box-list-item" filter-name="freeSpace" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Espaço para convivência</div>
                  <div class="filter-add-box-list-item" filter-name="kitchen" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Cozinha/copa</div>
                  <div class="filter-add-box-list-item" filter-name="coffe" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Café grátis</div>
                  <div class="filter-add-box-list-item" filter-name="meeting" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Sala de reuniões</div>
                  <div class="filter-add-box-list-item" filter-name="mail" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Endereço comercial (para correspondência)</div>
                  <div class="filter-add-box-list-item" filter-name="tax" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Endereço fiscal</div>
                  <div class="filter-add-box-list-item" filter-name="printer" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Serviço de impressão</div>
                  <div class="filter-add-box-list-item" filter-name="operator" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Serviço de secretariado</div>
                  <div class="filter-add-box-list-item" filter-name="privatePhone" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Telefone privado</div>
                  <div class="filter-add-box-list-item" filter-name="wheelchair" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Acessível para cadeirante</div>
                  <div class="filter-add-box-list-item" filter-name="bike" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Bicicletário</div>
                  <div class="filter-add-box-list-item" filter-name="parking" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Estacionamento conveniado</div>
                  <div class="filter-add-box-list-item" filter-name="privateParking" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Estacionamento privado</div>
                  <div class="filter-add-box-list-item" filter-name="creditcard" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Aceita cartões de crédito/débito</div>
                  <div class="filter-add-box-list-item" filter-name="leasing" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Aluga computadores</div>
                  <div class="filter-add-box-list-item" filter-name="englishStaff" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Atendimento em inglês</div>
                  <div class="filter-add-box-list-item" filter-name="spanishStaff" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Atendimento em espanhol</div>
                  <div class="filter-add-box-list-item" filter-name="librasStaff" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Atendimento em Libras</div>
                  <div class="filter-add-box-list-item" filter-name="fulltime" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Atendimento 24 horas</div>
                  <div class="filter-add-box-list-item" filter-name="highspeedweb" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Internet de alta velocidade</div>
                  <div class="filter-add-box-list-item" filter-name="redundantweb" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Internet redundante</div>
                  <div class="filter-add-box-list-item" filter-name="airconditioner" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Ar-condicionado</div>
                  <div class="filter-add-box-list-item" filter-name="memberevents" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Eventos para membros</div>
                  <div class="filter-add-box-list-item" filter-name="ergonomicchairs" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Cadeiras ergonômicas</div>
                  <div class="filter-add-box-list-item" filter-name="standingdesks" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Standing Desks</div>
                  <div class="filter-add-box-list-item" filter-name="call-box" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Cabine telefônica</div>
                  <div class="filter-add-box-list-item" filter-name="pet" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Permite animais</div>
                  <div class="filter-add-box-list-item" filter-name="kids-friendly" filter-active="false"><span class="filter-add-box-list-item-check"><span></span></span> Estrutura para crianças</div>
                </div>
                <div class="filter-add-box-buttons">
                  <a class="filter-add-box-button-apply" id="filter-add-box-button-apply" href="#">Aplicar&nbsp;filtros</a>
                  <a class="filter-add-box-button-remove" id="filter-add-box-button-remove" href="#">Remover&nbsp;todos</a>
                </div>
              </div>
            </div>
            <a class="region-spaces-filters-alt-remove" id="region-spaces-filters-alt-remove" href="#">Remover&nbsp;Filtros</a>
          </div>


      </div>
      
    </div>

    

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col" style="min-width: 110px;">Tipo</th>
            <th scope="col">Espaço</th>
            <th scope="col">Bairro</th>
            <th scope="col">Cidade</th>
            <th scope="col">UF</th>
            <th scope="col"></th>
            <th scope="col" style="min-width: 200px;">E-mail</th>
            <th scope="col">Telefone</th>
            <th scope="col">Site</th>
            <th scope="col">Plan</th>
            <th scope="col" title="24h">
                <img draggable="false" role="img" class="emoji" alt="🌙" src="https://s.w.org/images/core/emoji/13.0.0/svg/1f319.svg">
            </th>
            <th scope="col" title="Acessível para cadeirante">
                <img draggable="false" role="img" class="emoji" alt="♿" src="https://s.w.org/images/core/emoji/13.0.0/svg/267f.svg">
            </th>
            <th scope="col" title="Estacionamento">
                <img draggable="false" role="img" class="emoji" alt="🚗" src="https://s.w.org/images/core/emoji/13.0.0/svg/1f697.svg">
            </th>
            </tr>
        </thead>
        <tbody>
            <Espacos v-for="(espaco, index) in todosSpaces_"
                        :espaco="espaco"                    
                        :key="espaco.id"
                        :index="index"
                        />
        </tbody>
      </table>
    </div>
    <div id="loadBox" class="d-flex justify-content-center">
      <div :class="objLoading[4] ? 'loading' : ''" class="loadind-clientes spinner-border">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</template>



<script>
import Espacos from "@/components/Espacos";
import { mapActions, mapGetters } from "vuex";


export default {
  name: "app",
  components: { Espacos },
  data(){
      return {
        espaco_features: [],
        type_value_mode: (this.$route.query["type-value-mode"]!=undefined?this.$route.query["type-value-mode"]:""),
        select_number: (this.$route.query["select-number"]!=undefined?this.$route.query["select-number"]:""),
        city: (this.$route.query.city!=undefined?this.$route.query.city:""),
        features: (this.$route.query.features!=undefined?this.$route.query.features:""),
      }
  },
  computed: {
    ...mapGetters(["todosSpaces", "objLoading", "espacoFiltros"]),
    todosSpaces_(){

      //console.log(this.todosSpaces);

      function filterAply(value){

        if(typeof value == "object" && this.espaco_features.constructor.name == "Array"){

          if(typeof value["featuresArr"] == "object"){
            value["featuresArr"] = Array.from(value["featuresArr"]);
          }

          if(this.espaco_features.every((val) => value["featuresArr"].includes(val))){
            return value;
          }
        }
      }

      return this.todosSpaces.filter(filterAply, this);
    }
  },
  methods: {
    ...mapActions(["load_spaces"]),
  },
  mounted () {


        console.log("type-value-mode");
        console.log(this.type_value_mode);
        console.log("select-number");
        console.log(this.select_number);
        console.log("city");
        console.log(this.city);
        console.log("features");
        console.log(this.features);
        console.log("this.$route");
        console.log(this.$route);
    
    this.load_spaces([this.$router, this.$route.query["type-value-mode"], this.$route.query["select-number"], this.$route.query.city]);

    
    /*
*
*/

console.log("this");
console.log(this);
var _this = this;


document.querySelectorAll('[filter-name]').forEach(element => {
    element.addEventListener('click', function (e) {
        console.log(e);
        var tempFilter = "";
        if(element.hasAttribute("filter-active") && element.getAttribute("filter-active") == "true"){
          element.setAttribute("filter-active", "false");
          tempFilter = "false";
        }else{
          tempFilter = "true";
        }
        element.setAttribute("filter-active", tempFilter);
        if(element.hasAttribute("filter-type") && element.getAttribute("filter-type") == "primary"){
          document.querySelectorAll('[filter-name="'+element.getAttribute("filter-name")+'"]').forEach(element2 => {
            element2.setAttribute("filter-active", tempFilter);
          });
          filterSpaces();
        }
    });
});
var closeFilterAdd = false;

gId("region-spaces-filter-add").addEventListener('click', function (e) {
  e.target;
  if(closeFilterAdd){
    gId("filter-add-box").style.display = "none";
  }else{
    gId("filter-add-box").style.display = "flex";
  }
  closeFilterAdd = false;
});

window.addEventListener('click', function(e){
  if (gId("region-spaces-filter-add").contains(e.target)){
    // Clicked in box
  } else{
    gId("filter-add-box").style.display = "none";
  }
});
function closeFiltersBox(){
  console.log("OI");
  console.log(gId("filter-add-box"));
  gId("filter-add-box").style.display = "none";
  closeFilterAdd = true;
  //return false;
}
document.getElementById("filter-add-box-close").addEventListener("click", closeFiltersBox);

function btnFilterSpaces(){
  filterSpaces();
  gId("filter-add-box").style.display = "none";
  closeFilterAdd = true;
}
document.getElementById("filter-add-box-button-apply").addEventListener("click", btnFilterSpaces);
function btnRemoveFilterSpaces(){
  console.log("btnRemoveFilterSpaces");
  gId("region-spaces-filter-add").querySelectorAll('.filter-add-box-list-item').forEach(filter => {
    filter.setAttribute("filter-active", "false");
  })
  btnFilterSpaces();
}
document.getElementById("filter-add-box-button-remove").addEventListener("click", btnRemoveFilterSpaces);
function btnRemoveFilterSpacesAlt(){
  btnRemoveFilterSpaces();
  closeFilterAdd = false;
}
document.getElementById("region-spaces-filters-alt-remove").addEventListener("click", btnRemoveFilterSpacesAlt);

function filterSpaces(saveHistory = true){

console.log("this");
console.log(this);

  var arrFilters = [];
  var activiesFilters = 0;
  var activiesPrimaryFilters = 0;
  gId("region-spaces-filter-add").querySelectorAll('[filter-active="true"]').forEach(filter => {
    arrFilters.push(filter.getAttribute("filter-name"));
    var primaryFilter = document.querySelector("[filter-name='"+filter.getAttribute("filter-name")+"'][filter-type='primary']");
    if(primaryFilter){
      primaryFilter.setAttribute("filter-active", "true");
      activiesPrimaryFilters++;
    }
    activiesFilters++;
  });
  gId("region-spaces-filter-add").querySelectorAll('[filter-active="false"]').forEach(filter => {
    var primaryFilter = document.querySelector("[filter-name='"+filter.getAttribute("filter-name")+"'][filter-type='primary']");
    if(primaryFilter){
      primaryFilter.setAttribute("filter-active", "false");
    }
  });

  _this.espaco_features = arrFilters;


  // var allSpaces = false;
  // if(arrFilters.length == 0){
  //   allSpaces = true;
  // }

  var arrSpaces = [];
  var spacePlansCount = 0;
  // for (var i = 0; i < list.length; i++) {
  //   var arrSpaceFeature = list[i].space_features.split(";");
  //   if(allSpaces || arrayContainsArray(arrSpaceFeature, arrFilters)){
  //     arrSpaces.push(""+list[i].space_id);
  //     spacePlansCount += list[i].space_plans_count;
  //   }
  // }

  console.log("activiesFilters");
  console.log(activiesFilters);
  console.log("activiesPrimaryFilters");
  console.log(activiesPrimaryFilters);
  console.log("activiesFilters");
  console.log(activiesFilters);
  
  if(activiesFilters > activiesPrimaryFilters || activiesFilters > 0){
    gId("region-spaces-filters").classList.add("alt");
    gId("filter-add-box-label").innerHTML = "Editar filtros";
    var txtFiltersAlt = "";
    spacePlansCount;
    // if(spacePlansCount == 0){
    //   txtFiltersAlt = "Nenhum plano corresponde ";
    // }else if(spacePlansCount == 1){
    //   txtFiltersAlt = spacePlansCount+" plano corresponde ";
    // }else{
    //   txtFiltersAlt = spacePlansCount+" planos correspondem ";
    // }
    // {% endif %}
    if(activiesFilters == 1){
      txtFiltersAlt += "1 filtro aplicado.";
    }else{
      txtFiltersAlt += activiesFilters+" filtros aplicados.";
    }
    gId("region-spaces-filters-alt").innerHTML = txtFiltersAlt;
  }else{
    gId("region-spaces-filters").classList.remove("alt");
    gId("filter-add-box-label").innerHTML = "Mais filtros";
  }
  //gId("region-spaces-filters").classList.add("alt");
  ////#countSpace
  //gId("countSpaces-row").style.display = "flex";
  //gId("countSpaces").innerHTML = arrSpaces.length + (arrSpaces.length?" espaços":" espaço");

  if(activiesFilters == 0){
    gId("filter-add-box-label-mobile").innerHTML = "Filtrar espaços";
    gId("filter-add-box-label-mobile").classList.remove("active");
    //gId("countSpaces-row").style.display = "none";
  }else if(activiesFilters == 1){
    gId("filter-add-box-label-mobile").innerHTML = activiesFilters+" filtro aplicado";
    gId("filter-add-box-label-mobile").classList.add("active");
  }else{
    gId("filter-add-box-label-mobile").innerHTML = activiesFilters+" filtros aplicados";
    gId("filter-add-box-label-mobile").classList.add("active");
  }

  var quantPromo = 0;
  var quantTrad = 0;
  var quantFree = 0;
  document.querySelectorAll('[space-id]').forEach(spaceArticle => {
    if(arrSpaces.includes(spaceArticle.getAttribute("space-id"))){
      spaceArticle.style.display = "flex";
      if(spaceArticle.classList.contains("region-space-promo")){
        if(quantPromo%2==1){
          spaceArticle.classList.add("region-space-promo-even");
        }else{
          spaceArticle.classList.remove("region-space-promo-even");
        }
        quantPromo++;
      }else if(spaceArticle.classList.contains("region-space-trad")){
        if(quantTrad == 0 && quantPromo%2==1){
          spaceArticle.classList.add("chage-promo");
          spaceArticle.classList.remove("region-space-trad-even");
          // spaceArticle.querySelector("img").style.width = "100%";
          // spaceArticle.querySelector("img").style.height = "100%";
          quantPromo++;
        }else{
          spaceArticle.classList.remove("chage-promo");
          if((quantTrad-1)%3==0){
            spaceArticle.classList.add("region-space-trad-even");
          }else{
            spaceArticle.classList.remove("region-space-trad-even");
          }
          quantTrad++;
        }
      }else{
        if((quantFree-1)%3==0){
          spaceArticle.classList.add("region-space-open-even");
        }else{
          spaceArticle.classList.remove("region-space-open-even");
        }
        quantFree++;
      }
    }else{
      spaceArticle.style.display = "none";
    }
  });


 
  if(saveHistory){//Integrate with Advanced Search
    var strFilter = "";
    var strFilterTmp = "";
    for (var i = 0; i < arrFilters.length; i++) {
      strFilterTmp += arrFilters[i];
      if(i != arrFilters.length-1){
        strFilterTmp += ";";
      }
    }
    strFilter += strFilterTmp;
    _this.features = strFilter
    // if(strFilter == "features="){
    //   document.getElementById('features').setAttribute("value", "");
    // }else{
    //   document.getElementById('features').setAttribute("value", strFilterTmp);
    // }
    var adSearch = "type-value-mode="+_this.type_value_mode+"&select-number="+_this.select_number+"&city="+_this.city+"&"
    var filterSearch = "features="+strFilter
    history.pushState("", "", "?"+adSearch+filterSearch);
  }


  // var newSpaceList = [];
  // for (i = 0; i < list.length; i++) {
  //   if(arrSpaces.includes(""+list[i].space_id)){
  //     newSpaceList.push(list[i]);
  //   }
  // }
  // if(typeof initializeObj === "function"){
  //   initializeObj(newSpaceList);
  // }

  return false;
}



var arrloadFilters = this.features.split(";");
for (var i = 0; i < arrloadFilters.length; i++) {
  if(gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]')){
    gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]').setAttribute("filter-active", "true");
  }
  //console.log(gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]'));
}

filterSpaces(false);

// {% if loadFilters%}
// var loadFilters = "{{loadFilters}}";
// var arrloadFilters = loadFilters.split(";");
// for (var i = 0; i < arrloadFilters.length; i++) {
//   if(gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]')){
//     gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]').setAttribute("filter-active", "true");
//   }
//   if(arrloadFilters[i] == "reserva_online"){
//     reservaOnline = true;
//     document.getElementById('reserva-online-box').classList.add("active");
//   }
//   //console.log(gId("region-spaces-filter-add").querySelector('[filter-name="'+arrloadFilters[i]+'"]'));
// }

// filterSpaces(false);
// {% endif %}


function gId(id){
  return document.getElementById(id);
}
gClass('');
function gClass(clas){
  return document.getElementsByClassName(clas);
}

// arrayContainsArray();
// function arrayContainsArray(superset, subset) {
//   if (0 === subset.length || superset.length < subset.length) {
//     return false;
//   }
//   for(var i = 0; i < subset.length; i++) {
//     if(superset.indexOf(subset[i]) === -1) return false;
//   }
//   return true;
// }


    
  },
};





</script>







<style scoped>
  .table{
    word-break: break-word;
  }

  .filter-box-d{
    margin-left: 64px;
  }


  .filtro-label{
    margin-right: 2px;
    margin-left: 8px;
  }
  @media (max-width: 768px) {
    .filtro-box-group{
      flex-direction: column;
      margin-top: 24px;
    }
    .filtro-box{
      width: 100%;
      margin-bottom: 12px;
    }
    .filtro-label{
      margin-left: 0px;
    }

    .space-query{
      flex-direction: column;
      align-items: center!important;
      margin-bottom: 36px;
    }
    .filtro-box{
      text-align: center;
    }
    .filter-box-d{
      margin-right: 64px;
    }
  }

  .space-query-link{
    margin-left: 8px;
  }
  

  .loadind-clientes{
    margin-top: 12px;
    height: 25px;
    width: 25px;
    font-size: 12px;
    display: none;
  }

  .loading.loadind-clientes{
    display: block;
  }
</style>
<style lang="scss">

.region-asearch-filters{
    margin-bottom: 32px;
  }
  .asearch-form{
    width: 100%;
  }
  .region-asearch-filters-title{
    color: #212121;
    font-size: 16px;
  }
  .region-asearch-filters-row{
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .region-asearch-input-group{
    position: relative;
    margin-right: 0px;
    width: calc(100%);
    margin-bottom: 12px;
  }
  .region-asearch-input-group.last{
    flex-grow: 1;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .region-asearch-input-ico{
    position: absolute;
    height: 100%;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9FA9B1;
  }
  .region-asearch-input-submit-ico{
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9FA9B1;
  }
  .select-ico{
    display: none;
  }
  .select-ico.active{
    display: inherit;
    i{
      display: inherit;
    }
  }
  .region-asearch-input{
    border: none;
    padding: 8px 8px 8px 32px;
    font-family: "Segoe UI";
    font-size: 15px;
    flex-grow: 1;
    border: 1px solid #C1C7CE;
    border-radius: 2px;
    width: 100%;
    color: #565757;
    background-color: #fff;
  }
  .region-asearch-input:focus{
    outline: none;
  }
  .region-asearch-input-submit{
    padding: 9px 32px 9px 32px;
  }

  .region-spaces{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  .region-spaces-filters-prymary{
    display: none;
  }
  .region-spaces-filters-alt{
    display: none;
  }
  .region-spaces-filters-alt-remove{
    display: none;
  }
  .filter-add-box-label{
    display: none;
  }
  .filter-add-box-label-mobile{
    width: 100%;
    background-color: #fff;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #000;
    color: #212121;
    font-size: 12px;
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }
  .filter-add-box-label-mobile.active{
    border: 1px solid #26C6DA;
    color: #26C6DA;
  }
  .filter-add-box{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    display: none;
    flex-direction: column;
    padding: 18px 14px;
    height: 100vh;
    width: 100%;
    overflow: scroll;
  }
  .filter-add-box-mark{
    display: none;
  }
  .filter-add-box-close{
    position: absolute;
    right: 24px;
    top: 8px;
    color: #78909c;
    font-size: 24px;
    font-weight: bold;
  }
  .filter-add-box-title{
    margin: 4px 8px 16px;
    font-size: 32px;
  }
  .filter-add-box-list{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .filter-add-box-list-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    color: #212121;
    cursor: pointer;
  }
  .filter-add-box-list-item-check{
    height: 14px;
    width: 14px;
    border: 1px solid #212121;
    border-radius: 2px;
    background-color: #fff;
    margin-right: 6px;
    position: relative;
    flex-shrink: 0;
  }
  .filter-add-box-list-item[filter-active = "true"] > .filter-add-box-list-item-check > span{
    position: absolute;
    top: 1px;
    left: 1px;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    background-color: #212121;
  }
  div[filter-name="call-box"], div[filter-name="standingdesks"], div[filter-name="ergonomicchairs"], div[filter-name="memberevents"], div[filter-name="airconditioner"], div[filter-name="redundantweb"], div[filter-name="pet"]{
    display: none !important;
  }
  .filter-add-box-buttons{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
  }
  .filter-add-box-button-apply{
    width: 100%;
    background-color: #26C6DA;
    padding: 6px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    display: block;
    text-align: center;
  }
  .filter-add-box-button-remove{
    width: 100%;
    color: #EF5350;
    font-size: 13px;
    display: block;
    text-align: center;
    margin-top: 16px;
  }



  @media(min-width: 1200px){
  /*Advanced Search - search filters - desktop*/


.region-spaces-box{
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.region-asearch-filters-row{
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.region-asearch-input-group{
  margin-right: 12px;
  width: inherit;
  margin-bottom: 0px;
}



  .region-spaces-filters{
    font-size: 16px;
    margin-bottom: 32px;
    color: #78909C;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .region-spaces-filter{
    font-size: 12px;
    padding: 2px 12px;
    border: 1px solid #78909C;
    color: #78909C;
    border-radius: 12px;
    margin: 0 3px;
    cursor: pointer;
  }
  @media(max-width: 1680px){
    .region-spaces-filter-xl{
      display: none;
    }
  }
  .region-spaces-filter > i{
    color: #ECEFF1;
    width: 12px;
  }
  .region-spaces-filter > i.fa-check{
    display: none;
  }
  .region-spaces-filter[filter-active = "true"]{
    border: 1px solid #26C6DA;
    color: #fff;
    background-color: #26C6DA;
  }
  .region-spaces-filter[filter-active = "true"] > i{
    color: #fff;
  }
  .region-spaces-filter[filter-active = "true"] > i.fa-check{
    display: inline;
  }
  .region-spaces-filter[filter-active = "true"] > i.fa-square{
    display: none;
  }
  .region-spaces-filters-alt{
    display: none;
  }
  .region-spaces-filters-alt-remove{
    font-size: 13px;
    color: #EF5350;
    display: none;
    margin-left: 8px;
  }
  .region-spaces-filters-prymary{
    display: inline;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  .region-spaces-filters.alt .region-spaces-filters-prymary{
    display: none;
  }
  .region-spaces-filters.alt .region-spaces-filters-alt{
    display: inline;
  }
  .region-spaces-filters.alt .region-spaces-filters-alt-remove{
    display: inline;
  }
  .region-spaces-filter-add{
    font-size: 13px;
    position: relative;
    color: #26C6DA;
    display: inline;
    margin-left: 8px;
    cursor: pointer;
    margin-top: 8px;
  }
  .filter-add-box-label{
    display: inline;
  }
  .filter-add-box-label-mobile{
    display: none;
  }
  .filter-add-box{
    position: absolute;
    top: 32px;
    right: 0px;
    background-color: #fff;
    border: 1px solid #78909C;
    border-radius: 2px;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    display: flex;
    display: none;
    flex-direction: column;
    padding: 18px 14px;
    height: auto;
    width: auto;
    left: inherit;
    overflow: auto;
    width: 260px;
  }
  .filter-add-box-mark{
    display: block;
    height: 24px;
    width: 24px;
    background-color: #fff;
    position: absolute;
    top: -13px;
    right: 11px;
    border-top: 1px solid #78909C;
    border-left: 1px solid #78909C;
    border-top-left-radius: 6px;
    transform: rotateZ(45deg) rotate3d(1, 1, 0, 53deg);
  }
  .filter-add-box-close{
    display: none;
  }
  .filter-add-box-title{
    display: none;
  }
  .filter-add-box-list{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
  .filter-add-box-list-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    color: #212121;
    cursor: pointer;
  }
  .filter-add-box-list-item-check{
    height: 14px;
    width: 14px;
    border: 1px solid #212121;
    border-radius: 2px;
    background-color: #fff;
    margin-right: 6px;
    position: relative;
    flex-shrink: 0;
  }
  .filter-add-box-list-item[filter-active = "true"] > .filter-add-box-list-item-check > span{
    position: absolute;
    top: 1px;
    left: 1px;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    background-color: #212121;
  }
  .filter-add-box-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
  .filter-add-box-button-apply{
    text-decoration: none;
    padding: 6px 16px;
    border-radius: 5px;
    transition: background-color .4s,color .4s;
    color: #fff;
    background-color: #26c6da;
    width: inherit;
    font-size: 12px;
  }
  .filter-add-box-button-remove{
    text-decoration: none;
    padding: 4px 16px;
    border-radius: 5px;
    color: #EF5350;
    background-color: #fff;
    width: inherit;
    font-size: 13px;
    margin-top: 0px;
  }
  .region-asearch-filters-row{
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .region-asearch-input-group{
    margin-right: 12px;
    width: inherit;
    margin-bottom: 0px;
  }
}

</style>