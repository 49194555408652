<template>
    <div class="dadoTexto" :class="cliente[clienteData].editMode ? 'edit' : ''">
      <span class="get" :class="clienteData + ' ' + cliente[clienteData].value"
      >{{ selectOpcoes[clienteData+"Legenda"][cliente[clienteData].value] }}</span>
      <select v-model.lazy="cliente[clienteData].value" class="update" name="" id="">
            <option v-for="(opcao, index) in selectOpcoes[clienteData]" :key="index" :value="opcao">{{ selectOpcoes[clienteData+"Legenda"][opcao]}}</option>
      </select>
      <a @click.prevent="$emit('editDataCliente', [cliente, clienteData])" class="get edit-link" href="#"><i class="fas fa-pencil-alt"></i></a>
      <a @click.prevent="$emit('editDataClienteConcluir', [cliente, clienteData])" class="update edit-link" href="#"><i class="fas fa-check"></i></a>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    cliente: { type: Object, required: true },
    clienteData: { type: String, required: true },
  },
  computed: {
    ...mapGetters(["selectOpcoes"]),
  },
};
</script>

<style scoped>
div.dadoTexto{
    position: relative;
    padding-right: 32px;
    min-height: 20px;
    min-width: 20px;
  }

  .edit-link{
    color: rgb(108, 25, 185);
    position: absolute;
    right: 4px;
    top: 0px;
    display: none;
  }
  div.dadoTexto:hover > .edit-link{
    display: block
  }

  .update{
    display: none !important;
  }
  .edit > .get{
    display: none !important;
  }
  .edit > .update{
    display: inline !important;
  }

  .gateway{
    display: inline-block;
    background: #999;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
  }
  .gateway.stripe, .gateway.STR{
    background: #651FFF;
  }
  .gateway.ind{
    background: #999;
  }
  .gateway.boleto{
    background: #795548;
  }
  .gateway.paypal{
    background: #2979FF;
  }

  .plano{
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 8px;
    display: inline-block;
    font-weight: 400;
    background-color: #BBC3C9;
  }

  .plano.administrator{
    background-color: #BBC3C9;
  }
  .plano.coworking_free{
    background-color: transparent;
    border: 1px solid #333;
    color: #333;
  }
  .plano.espaco_cancelado{
    background-color: #e74c3c;
  }
  .plano.espaco_open_list{
    background-color: #999;
  }
  .plano.espaco_tradicional{
    background-color: #3498DB;
  }
  .plano.espaco_destaque{
    background-color: #745EC5;
  }
  .plano.coworking_destaque{
    background-color: transparent;
    border: 1px solid #745EC5;
    color: #745EC5;
  }
  .plano.espaco_destaque_promo{
    background-color: #FF9673;
  }
  .plano.espaco_tradicional_v2{
    background-color: #0098DB;
  }
  .plano.gerente{
    background-color: #BBC3C9;
  }
  .plano.author{
    background-color: #BBC3C9;
  }
  .plano.content_manager{
    background-color: #BBC3C9;
  }
</style>