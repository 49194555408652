import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home'
import Cliente from '@/views/single_cliente/Single_Cliente'
import Login from '@/views/Login'
import Lista_Espacos from '@/views/Lista_Espacos'

Vue.use(Router)

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        name: 'cliente',
        path: '/cliente/:id',
        component: Cliente,
    },
    {
        name: 'login',
        path: '/login/',
        component: Login,
    },
    {
        name: 'espacos',
        path: '/espacos/',
        component: Lista_Espacos,
    },
]

const router = new Router({ routes, mode: "history" })

export default router