<template>
  <tr :class="lead.loading ? 'cliente-loading' : ''">
    <th class="col-nome" scope="row"> 
      <span class="table-hash">{{ lead.nome }}</span>
      <div class="table-loadind spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </th>
    <td class="text-nowrap">{{ lead.mail }}</td>
    <td class="text-nowrap">{{ lead.telefone }}</td>
    <td class="col-mensagem">{{ lead.menssagem }}</td>
    <td class="text-nowrap">{{ lead.unidade }}</td>
    <td class="text-nowrap">{{ lead.data }}</td>
  </tr>
</template>



<script>
import { mapActions } from "vuex";

export default {
  props: {
    lead: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  methods: {
    ...mapActions(["editDataCliente", "editDataClienteConcluir"]),
  }
};
</script>

<style scoped>

  .col-mensagem{
    max-width: 300px;
  }
  .col-nome{
    max-width: 230px;
  }

  .cliente-loading{
    opacity: 0.3;
    pointer-events: none;
  }
  .table-loadind{
    height: 20px;
    width: 20px;
    font-size: 12px;
    display: none;
  }
  .cliente-loading .table-loadind{
    display: inline-block;
  }
  .cliente-loading .table-hash{
    display: none;
  }

  .label{
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 8px;
    display: inline-block;
    font-weight: 400;
    background-color: #BBC3C9;
  }
  
  .administrator{
    background-color: #BBC3C9;
  }
  .coworking_free{
    background-color: transparent;
    border: 1px solid #333;
    color: #333;
  }
  .espaco_cancelado{
    background-color: #e74c3c;
  }
  .espaco_open_list{
    background-color: #999;
  }
  .espaco_tradicional{
    background-color: #3498DB;
  }
  .espaco_destaque{
    background-color: #745EC5;
  }
  .coworking_destaque{
    background-color: transparent;
    border: 1px solid #745EC5;
    color: #745EC5;
  }
  .espaco_destaque_promo{
    background-color: #FF9673;
  }
  .espaco_tradicional_v2{
    background-color: #0098DB;
  }
  .gerente{
    background-color: #BBC3C9;
  }
  .author{
    background-color: #BBC3C9;
  }
  .content_manager{
    background-color: #BBC3C9;
  }

  .tag{
    display: inline-block;
    background: #999;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
  }
  .tag.STR{
    background: #651FFF;
  }
  .tag.IND{
    background: #999;
  }
  .tag.BOL{
    background: #795548;
  }
  .tag.PAY{
    background: #2979FF;
  }

</style>