<template>
  <div class="container-fluid">

    <section class="row">
      <div class="col">
        <h1>Clientes <i class="bi bi-pencil"></i></h1>
      </div>
    </section>

    <div class="d-flex justify-content-end mb-4 filtro-box-group">

      <div class="filtro-box">
        <span class="filtro-label">Plano: </span>
        <select v-model="str_plano">
              <option value="">Todos</option>
              <option v-for="(opcao, index) in selectOpcoes.plano" :key="index" :value="opcao">{{ selectOpcoes.planoLegenda[opcao]}}</option>
        </select>
      </div>
      
      <div class="filtro-box">
        <span class="filtro-label">Status: </span>
        <select v-model="str_status">
              <option value="">Todos</option>
              <option v-for="(opcao, index) in selectOpcoes.status" :key="index" :value="opcao">{{ selectOpcoes.statusLegenda[opcao]}}</option>
        </select>
      </div>

      <div class="filtro-box">
        <span class="filtro-label">Gateway: </span>
        <select v-model="str_gateway">
              <option value="">Todos</option>
              <option v-for="(opcao, index) in selectOpcoes.gateway" :key="index" :value="opcao">{{ selectOpcoes.gatewayLegenda[opcao]}}</option>
        </select>
      </div>
      
      <div class="filtro-box">
        <span class="filtro-label">Filtro: </span>
        <input class="" type="text" v-model="str_filtro" placeholder="Espaço/Nome/E-mail">
      </div>
      
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" style="min-width: 46px;">#</th>
            <th scope="col" style="min-width: 55px;">ID</th>
            <th scope="col" style="min-width: 200px;">Espaço</th>
            <th scope="col" style="min-width: 200px;">Nome</th>
            <th scope="col" style="min-width: 200px;">E-mail</th>
            <th scope="col">Plano</th>
            <th scope="col" style="min-width: 125px;">Status</th>
            <th scope="col" style="min-width: 98px;">Gateway</th>
            <th scope="col" style="min-width: 158px;">Data de renovação</th>
          </tr>
        </thead>
        <tbody>
          <cliente v-for="(cliente, index) in todosClientes_Filtro"
                :cliente="cliente"
                :key="cliente.id"
                :index="index"
                @editDataCliente="editDataCliente"/>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center">
      <div :class="objLoading[0] ? 'loading' : ''" class="loadind-clientes spinner-border">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</template>

<script>
import Cliente from "@/components/Cliente";
import { mapActions, mapGetters } from "vuex";


export default {
  name: "app",
  components: { Cliente },
  data(){
      return {
        str_filtro: (this.$route.query.q!=undefined?this.$route.query.q:""),
        str_plano: (this.$route.query.plano!=undefined?this.$route.query.plano:""),
        str_status: (this.$route.query.status!=undefined?this.$route.query.status:""),
        str_gateway: (this.$route.query.str_gateway!=undefined?this.$route.query.str_gateway:""),
      }
  },
  computed: {
    ...mapGetters(["todosClientes", "objLoading", "strPlanos", "strStatus", "selectOpcoes"]),
    todosClientes_Filtro() {


      console.log("HOME");
      console.log(this.$route.query.status)

        var param = "?";
        if(this.str_status != ""){
          param += "status=" + this.str_status;
        }
        if(this.str_plano != ""){
          if(param != "?"){
            param += "&";
          }
          param += "plano=" + this.str_plano;
        }
        if(this.str_gateway != ""){
          if(param != "?"){
            param += "&";
          }
          param += "gateway=" + this.str_gateway;
        }
        if(this.str_filtro != ""){
          if(param != "?"){
            param += "&";
          }
          param += "q=" + this.str_filtro;
        }

        // if(param == "?"){
        //   param = ""
        // }


        history.pushState("", "", param);

        return this.todosClientes.filter(item => ((item.nome.value.toLowerCase().includes(this.str_filtro.toLowerCase()) || item.espaco.value.toLowerCase().includes(this.str_filtro.toLowerCase()) || item.mail.value.toLowerCase().includes(this.str_filtro.toLowerCase()))
          && (this.str_plano == "" || item.plano_aux == this.str_plano)
          && (this.str_status == "" || item.status.value == null || item.status.value.toLowerCase() == this.str_status.toLowerCase())
          && (this.str_gateway == "" || item.gateway.value == null || item.gateway.value.toLowerCase() == this.str_gateway.toLowerCase()) ))

        // console.log(typeof this.todosClientes);

        // const index = this.todosClientes.findIndex(item => item.id == this.$route.params.id);


        // if (index > -1) {
        //     return this.todosClientes[index]
        // }
        // return {}
    }
  },
  methods: {
    ...mapActions(["editDataCliente", "editDataClienteConcluir", "load"]),
  },
  mounted () {

        console.log("status");
        console.log(this.$route.query.status);
        console.log("plano");
        console.log(this.$route.query.plano);
        console.log("q");
        console.log(this.$route.query.q);
    
    this.load([this.$router]);
    
  },
};

</script>

<style scoped>
  .table{
    word-break: break-word;
  }


  .filtro-label{
    margin-right: 8px;
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    .filtro-box-group{
      flex-direction: column;
      margin-top: 24px;
    }
    .filtro-box{
      width: 100%;
      margin-bottom: 12px;
    }
    .filtro-label{
      margin-left: 0px;
    }
  }

  

  .loadind-clientes{
    margin-top: 12px;
    height: 25px;
    width: 25px;
    font-size: 12px;
    display: none;
  }

  .loading.loadind-clientes{
    display: block;
  }
</style>