import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueResource from 'vue-resource'

Vue.use(VueResource);
Vue.use(Vuex)
//console.log("process.env")
//console.log(process.env)


//console.log(process.env.VUE_APP_NET_BRANCH)

let baseurl;
if(process.env.VUE_APP_NET_BRANCH == "local") baseurl = process.env.VUE_APP_API_URL
else if(process.env.VUE_APP_NET_BRANCH == "master") baseurl = process.env.VUE_APP_API_URL
else baseurl = "https://cwbrstg.wpengine.com/"

//baseurl = "https://cwbrstg.wpengine.com/"

//console.log(baseurl)

const state = {


    loading_vars : [
        true, //load_clientes
        false, //load_espacos
        false, //load_leads
        false, //load_leads
        true, //load_spaces
    ],

    
    //baseurl: 'http://localhost/cwbr2016v4/app/',
    //authentic: {username: "", password: ""},
    // baseurl: 'https://cwbrstg.wpengine.com/',
    // authentic: {username: "", password: ""},
    
    baseurl: baseurl,
    authentic: {username: "", password: ""},

    str_token: [],

    //filtro_arr:{
        str_filtro: "",
        str_plano: "",
        str_status: "",
        str_gateway: "",
    //},

    espaco_filtros: {
        tipo: "",
        quantidade: "2",
        cidade: "São Paulo",
    },

    //espaco_features: [],



    select_opcoes: {
        gateway: ["ind", "stripe", "paypal", "boleto", "promo"],
        gatewayLegenda: {
            "ind": "Indefinido",
            "stripe": "Stripe",
            "paypal": "PayPal",
            "boleto": "Boleto",
            "promo": "Promocional"
        },
        renovacao: ["ind", "mensal", "semestral", "anual"],
        renovacaoLegenda: {
            "ind": "Indefinido",
            "mensal": "Mensal",
            "semestral": "Semestral",
            "anual": "Anual"
        },
        status: ["regular", "try", "past", "canceled"],
        statusLegenda: {
            "regular": "Regular",
            "try": "Atrasado",
            "past": "Aberto",
            "canceled": "Cancelado"
        },
        plano: ['espaco_destaque', 'coworking_destaque', 'espaco_destaque_promo', 'espaco_tradicional', 'espaco_tradicional_v2', 'coworking_tradicional', 'espaco_open_list', 'coworking_free', 'espaco_cancelado'],
        planoLegenda: {
            "espaco_destaque": "espaco_destaque",
            "coworking_destaque": "coworking_destaque",
            "espaco_destaque_promo": "espaco_destaque_promo",
            "espaco_tradicional": "espaco_tradicional",
            "espaco_tradicional_v2": "espaco_tradicional_v2",
            "coworking_tradicional": "coworking_tradicional",
            "espaco_open_list": "espaco_open_list",
            "coworking_free": "coworking_free",
            "espaco_cancelado": "espaco_cancelado",
        },
    },

    confirmEdit: [
        false,
        []
    ],

    clientes: [
        
    ],

    spaces: [
        
    ],
 }

const actions = {


    load_login({ commit }, payload){
        //router
        const router = payload[0];

        console.log(commit);
        //console.log(localStorage.str_token);
        //console.log(router);

        if(localStorage.str_token){

            router.push({ name: 'home' });
            
        }
    },


    login_token({ commit }, payload){
        //router
        const router = payload[0];

        //console.log(localStorage.str_token);

        var formdata = new FormData();
        formdata.append('action', 'api_auth');
        formdata.append('username', state.authentic.username);
        formdata.append('pass', state.authentic.password);

        axios.post(state.baseurl+'webhooks/', formdata).then(function(r){

            //console.log(r.data);
            if(r.data.auth == true){
                localStorage.str_token = r.data.token
                commit('load_token', r.data.token)
                router.push({ name: 'home' });
            }else{
                localStorage.removeItem("str_token");
                commit('load_token_error', r.data.token)
            }            
            
        }); 
        
    },
 


    load({ commit }, payload){
        //router
        const router = payload[0];
        state.clientes = [];

        // console.log("payload[0]");
        // console.log(payload);
        // commit('param', payload[1])
        
        if(localStorage.str_token){

            var formdata = new FormData();
            formdata.append('token', localStorage.str_token);
            formdata.append('action', 'vue_api_get_customer');

            commit('set_loading_clientes', true)
            axios.post(state.baseurl+'webhooks/', formdata).then(function(r){

                //console.log(r.data);
                commit('set_loading_clientes', false)
                if(r.data.auth !== false){
                    commit('load', r.data)
                }else{
                    localStorage.removeItem("str_token");
                    commit('load_token_error', r.data.token)
                    router.push({ name: 'login' });
                }            
                
            }); 
            
        }else{
            router.push({ name: 'login' });
        }
    },


    load_cliente({ commit }, payload){
        //id, router
        const id = payload[0];
        const router = payload[1];

        //console.log("localStorage.str_token");
        //console.log(localStorage.str_token);
        //console.log(router);

        if(localStorage.str_token){
                
                if(state.clientes.length == 0 && true == false){

                    var formdata = new FormData();
                    formdata.append('token', localStorage.str_token);
                    formdata.append('action', 'vue_api_get_customer');

                    commit('set_loading_clientes', true)
                    axios.post(state.baseurl+'webhooks/', formdata).then(function(r){

                        commit('set_loading_clientes', false)
                        //console.log(r.data);
                        if(r.data.auth !== false){
                            commit('load', r.data)

                            const index = state.clientes.findIndex(item => item.id == id);
                            //console.log(index);
                            //console.log(state.clientes[index].espacos.length == 0);
                            if(state.clientes[index].espacos.length == 0){
                                 formdata = new FormData();
                                 formdata.append('token', localStorage.str_token);
                                 formdata.append('action', 'vue_api_get_customer_data');
                                 formdata.append('id', id);

                                 commit('set_loading_espacos', true)
                                 commit('set_loading_leads', true)
                                 commit('set_loading_invoices', true)
                                 axios.post(state.baseurl+'webhooks/', formdata).then(function(r){

                                    commit('set_loading_espacos', false)
                                    commit('set_loading_leads', false)
                                    commit('set_loading_invoices', false)
                                    //console.log(r.data);
                                   if(r.data.auth !== false){
                                        //console.log(r.data);
                                        commit('load_data', r.data)
                                    }else{
                                        localStorage.removeItem("str_token");
                                        commit('load_token_error', r.data.token)
                                        router.push({ name: 'login' });
                                    }
                                 });
                            }


                        }else{
                            localStorage.removeItem("str_token");
                            commit('load_token_error', r.data.token)
                            router.push({ name: 'login' });
                        }            
                        
                    }); 



                
                }else{
                    //console.log("Aqio");
                    state.clientes[0] = {id: id, espacos: []};
                    const index = state.clientes.findIndex(item => item.id == id);
                    if(state.clientes[index].espacos.length == 0){
                        formdata = new FormData();
                        formdata.append('token', localStorage.str_token);
                        formdata.append('action', 'vue_api_get_customer_data');
                        formdata.append('id', id);

                        commit('set_loading_espacos', true)
                        commit('set_loading_leads', true)
                        commit('set_loading_invoices', true)
                        axios.post(state.baseurl+'webhooks/', formdata).then(function(r){

                            commit('set_loading_espacos', false)
                            commit('set_loading_leads', false)
                            commit('set_loading_invoices', false)
                            if(r.data.auth !== false){
                                //console.log(r.data);
                                commit('load_data', r.data)
                            }else{
                                localStorage.removeItem("str_token");
                                commit('load_token_error', r.data.token)
                                router.push({ name: 'login' });
                            }
                        });
                    }
                }
            
        }else{
            router.push({ name: 'login' });
        }
    },


    load_spaces({ commit }, payload){
        //router
        const router = payload[0];
        state.spaces = [];

        //console.log("payload[0]");
        //console.log(payload);
        commit('set_espacos_get', [(typeof payload[1] != "undefined" ? payload[1] : ""), (typeof payload[2] != "undefined" ? payload[2] : ""), (typeof payload[3] != "undefined" ? payload[3] : "")])
        
        
        if(localStorage.str_token){

            var formdata = new FormData();
            formdata.append('token', localStorage.str_token);
            formdata.append('action', 'vue_api_get_spaces');

            commit('set_loading_spaces', true)

            var getUrl = "type-value-mode=" + (typeof payload[1] != "undefined" ? payload[1] : "") + "&";
            getUrl += "select-number=" + (typeof payload[2] != "undefined" ? payload[2] : "") + "&";
            getUrl += "city=" + (typeof payload[3] != "undefined" ? payload[3] : "");

            //console.log("getUrl");
            //console.log(getUrl);
            //getUrl = "select-number=2&city=São Paulo";

            axios.post(state.baseurl+'webhooks/?'+getUrl, formdata).then(function(r){

                //console.log(r.data);
                commit('set_loading_spaces', false)
                if(r.data.auth !== false){
                    commit('load_spaces', r.data)
                }else{
                    localStorage.removeItem("str_token");
                    commit('load_token_error', r.data.token)
                    router.push({ name: 'login' });
                }            
                
            }); 
            
        }else{
            router.push({ name: 'login' });
        }
    },


    editDataCliente({ commit }, clienteObj) {
        commit('editDataCliente', clienteObj)
    },


    editDataClienteCancelar() {

        const index = state.clientes.findIndex(item => item.id === state.confirmEdit[1][0].id);
        if (index > -1) {
            const tempData = state.confirmEdit[1][0]
            tempData[state.confirmEdit[1][1]].value = tempData[state.confirmEdit[1][1]].confirmedit
            tempData[state.confirmEdit[1][1]].editMode = false

            //console.log("tempData");
            //console.log(tempData);
    
            Vue.set(state.clientes, index, tempData);
            Vue.set(state.confirmEdit, 0, false);
        }

    },
    

    editDataClienteConcluir({ commit }, clienteObj) {
        //id, router
        // const clienteObj = payload[0];
        // const router = payload[1];

        if(state.confirmEdit[0] == true){
            //console.log("state.confirmEdit[0] == true");
            //console.log(clienteObj);
            clienteObj = state.confirmEdit[1]
        }


        //console.log("clienteObj");
        //console.log(commit);
        //console.log(clienteObj);


        commit('setEditLoading', clienteObj.concat(true));


        if((state.confirmEdit[0] == true)){
            //console.log("Aqui");
            Vue.set(state.confirmEdit, 0, false);
        }else{
            if(clienteObj[1] == "stripe_id"){
                if(state.confirmEdit[0] == 1){
                    return
                }
                Vue.set(state.confirmEdit, 0, true);
                Vue.set(state.confirmEdit, 1, clienteObj);
                return
            }
        }
        


        const formdata = new FormData();
        formdata.append('token', localStorage.str_token);
        formdata.append('action', 'vue_api_update_customer');
        formdata.append('id', clienteObj[0].id);
        formdata.append('field', clienteObj[1]);
        formdata.append('value', clienteObj[0][clienteObj[1]].value);
        axios.post(state.baseurl+'webhooks/', formdata).then(function(r){
            if(r.data.auth !== false){
                //console.log(r.data);
                commit('editDataClienteConcluir', clienteObj)
                commit('setEditLoading', clienteObj.concat(false))
                //console.log(clienteObj);
            }else{
                // localStorage.removeItem("str_token");
                // commit('load_token_error', r.data.token)
                // router.push({ name: 'login' });
            }
        });
    },

    
    

    
}

const getters = {
    todosClientes(state) {
        return state.clientes;
    },
    todosSpaces(state) {
        return state.spaces;
    },
    selectOpcoes(){
        return state.select_opcoes;
    },
    strFiltro(){
        return state.str_filtro;
    },
    strPlanos(){
        return state.str_planos;
    },
    strStatus(){
        return state.str_status;
    },
    strGateway(){
        return state.str_gateway;
    },
    espacoFiltros(){
        return state.espaco_filtros;
    },    
    objAuth(){
        return state.authentic;
    },
    objLoading(){
        return state.loading_vars;
    },
    getUrl(){
        return baseurl;
    },
    getConfirmEdit(){
        return state.confirmEdit
    }

}

const mutations = {

    set_loading_clientes(state, payload) {
        Vue.set(state.loading_vars, 0, payload);
    },
    set_loading_spaces(state, payload) {
        Vue.set(state.loading_vars, 4, payload);
    },
    set_loading_espacos(state, payload) {
        Vue.set(state.loading_vars, 1, payload);
    },
    set_loading_leads(state, payload) {
        Vue.set(state.loading_vars, 2, payload);
    },
    set_loading_invoices(state, payload) {
        Vue.set(state.loading_vars, 3, payload);
    },

    set_espacos_get(state, payload) {
        Vue.set(state.espaco_filtros, "tipo", payload[0]);
        Vue.set(state.espaco_filtros, "quantidade", payload[1]);
        Vue.set(state.espaco_filtros, "cidade", payload[2]);
    },


    
    toggleEdit(state, payload) {
        //console.log("Teste");
        const tempData = state.clientes[0];
        tempData.nome.value = payload;
        Vue.set(state.clientes, 0, tempData);
    },
    
    load_token(state, payload) {
        Vue.set(state.str_token, 0, payload );
        //console.log(state);
    },

    load_token_error(state) {
        Vue.set(state.authentic, "username", "");
        Vue.set(state.authentic, "password", "");
        //console.log(state);
    },

    // param(state, payload){

    //     console.log("payload");
    //     console.log(payload);

    //     console.log("STORE");

    //     if(payload[0]){
    //         console.log(payload[0]);
    //         //state.str_status = payload[0]
    //         Vue.set(state, "str_status", payload[0]);
            
    //         console.log(state.str_status);
    //     }  
    //     if(payload[1]){
    //         //state.str_plano = payload[1]
    //         Vue.set(state, "str_plano", payload[1]);
    //     }
    //     if(payload[2]){
    //         //state.str_filtro = payload[2]
    //         Vue.set(state, "str_filtro", payload[2]);
    //     }      
        
    // },

    load(state, payload) {
        payload.clientes.forEach(function(element, i){
            Vue.set(state.clientes, i, element );
        });
    },

    load_data(state, payload) {
        //console.log(payload)
        const index = state.clientes.findIndex(item => item.id == payload.id);
        //console.log(payload.id)
        if (index > -1) {
            // const tempData = state.clientes[index];
            // tempData.espacos = payload.espacos;
            // tempData.lead = payload.leads;
            Vue.set(state.clientes, index, payload);
        }
    },

    load_spaces(state, payload) {
        //console.log(payload);
        payload.forEach(function(element, i){
            Vue.set(state.spaces, i, element );
        });
    },



    setEditLoading(state, payload) {
        const index = state.clientes.findIndex(item => item.id === payload[0].id);
        if (index > -1) {
            const tempData = state.clientes[index];
            tempData.loading = payload[2];
            Vue.set(state.clientes, index, tempData);
        }
    },

    editDataCliente(state, payload) {
        const index = state.clientes.findIndex(item => item.id === payload[0].id);
        if (index > -1) {
            //console.log(state.clientes[index][payload[1]].editMode)
            const checked = !state.clientes[index][payload[1]].editMode;
            const tempData = state.clientes[index];
            tempData[payload[1]].editMode = checked;
            Vue.set(state.clientes, index, tempData);
        }
    },

    editDataClienteConcluir(state, payload) {
        const index = state.clientes.findIndex(item => item.id === payload[0].id);
        if (index > -1) {
            //console.log(state.clientes[index][payload[1]].editMode)
            const checked = !state.clientes[index][payload[1]].editMode;
            const tempData = state.clientes[index];

            //console.log("payload");
            //console.log(payload);

            if(payload[1] == "username"){
                tempData["mail"].value = state.clientes[index]["username"].value
            }
            if(payload[1] == "mail"){
                tempData["username"].value = state.clientes[index]["mail"].value
            }
            if(payload[1] == "plano" && state.clientes[index]["plano_aux"] == "espaco_cancelado" && state.clientes[index][payload[1]].value != "espaco_cancelado"){
                tempData["status"].value = "regular"
            }
            tempData["plano_aux"] = state.clientes[index][payload[1]].value
            if(payload[1] == "status" && state.clientes[index][payload[1]].value == "canceled"){
                tempData["plano"].value = "espaco_open_list"
                tempData["plano_aux"] = "espaco_open_list"
            }
            if(payload[1] == "vencimento"){
                var parseData = new Date(payload[0][payload[1]].value);
                var dataFormatada = (adicionaZero(parseData.getDate()+1).toString() + "/" + (adicionaZero(parseData.getMonth()+1).toString()) + "/" + parseData.getFullYear());
                tempData["vencimento"].show = dataFormatada;
            }
            if(payload[1] == "stripe_id"){
                tempData["stripe_id"].confirmedit = payload[0][payload[1]].value
            }
            

            tempData[payload[1]].editMode = checked;
            Vue.set(state.clientes, index, tempData);
        }
    },
}

function adicionaZero(numero){
    if (numero <= 9) 
        return "0" + numero;
    else
        return numero; 
}



const store = new Vuex.Store({ state, actions, mutations, getters })

export default store