<template>
  <tr :class="cliente.loading ? 'cliente-loading' : ''">
    <th scope="row">
      <span class="table-hash">{{ index + 1 }}</span>
      <div class="table-loadind spinner-border" role="status">
        <span class="sr-only"></span>
      </div>  
    </th>
    <td><router-link :to="{name: 'cliente', params: {id: cliente.id}}" class="">{{ cliente.id }}</router-link></td>
    <td><strong>{{ cliente.espaco.value }}</strong></td>
    <td>{{ cliente.nome.value }}</td>
    <td>{{ cliente.mail.value }}</td>
    <td>
      <DadoSelect class="d-inline-block p-icon"
        :cliente="cliente"
        :clienteData="'plano'"
        @editDataCliente="editDataCliente"
        @editDataClienteConcluir="editDataClienteConcluir"/>
    </td>
    <td>
      <DadoSelect class="d-inline-block p-icon"
        :cliente="cliente"
        :clienteData="'status'"
        @editDataCliente="editDataCliente"
        @editDataClienteConcluir="editDataClienteConcluir"/>
    </td>
    <td>
      <!-- <span v-if="cliente.gateway.value != ''" class="tag" :class="cliente.gateway.value">{{ cliente.gateway.value }}</span> -->
      <DadoSelect class="d-inline-block p-icon"
        :cliente="cliente"
        :clienteData="'gateway'"
        @editDataCliente="editDataCliente"
        @editDataClienteConcluir="editDataClienteConcluir"/>
    </td>
    <td>
      <!-- <span v-if="cliente.gateway.value != ''" class="tag" :class="cliente.gateway.value">{{ cliente.gateway.value }}</span> -->
      <DadoTexto class="d-inline-block p-icon"
        :cliente="cliente"
        :clienteData="'vencimento'"
        :inputtype="'date'"
        @editDataCliente="editDataCliente"
        @editDataClienteConcluir="editDataClienteConcluir"/>
    </td>

  </tr>
</template>




<script>
import DadoTexto from "@/components/DadoTexto";
import DadoSelect from "@/components/DadoSelect";
import { mapActions } from "vuex";

export default {
  components: { DadoSelect, DadoTexto },
  props: {
    cliente: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  methods: {
    ...mapActions(["editDataCliente", "editDataClienteConcluir"]),
  }
};
</script>

<style scoped>

  .cliente-loading{
    opacity: 0.3;
    pointer-events: none;
  }
  .table-loadind{
    height: 20px;
    width: 20px;
    font-size: 12px;
    display: none;
  }
  .cliente-loading .table-loadind{
    display: inline-block;
  }
  .cliente-loading .table-hash{
    display: none;
  }

  .label{
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 8px;
    display: inline-block;
    font-weight: 400;
    background-color: #BBC3C9;
  }
  
  .administrator{
    background-color: #BBC3C9;
  }
  .coworking_free{
    background-color: transparent;
    border: 1px solid #333;
    color: #333;
  }
  .espaco_cancelado{
    background-color: #e74c3c;
  }
  .espaco_open_list{
    background-color: #999;
  }
  .espaco_tradicional{
    background-color: #3498DB;
  }
  .espaco_destaque{
    background-color: #745EC5;
  }
  .coworking_destaque{
    background-color: transparent;
    border: 1px solid #745EC5;
    color: #745EC5;
  }
  .espaco_destaque_promo{
    background-color: #FF9673;
  }
  .espaco_tradicional_v2{
    background-color: #0098DB;
  }
  .gerente{
    background-color: #BBC3C9;
  }
  .author{
    background-color: #BBC3C9;
  }
  .content_manager{
    background-color: #BBC3C9;
  }

  .tag{
    display: inline-block;
    background: #999;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 3px;
  }
  .tag.STR{
    background: #651FFF;
  }
  .tag.IND{
    background: #999;
  }
  .tag.BOL{
    background: #795548;
  }
  .tag.PAY{
    background: #2979FF;
  }

</style>