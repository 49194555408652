<template>
    <div class="dadoTexto" :class="cliente[clienteData].editMode ? 'edit' : ''">
      <span v-if="isLink==null" class="get" v-html="computedContent"></span>
      <a v-if="isLink!=null" class="get" v-html="computedContent" :href="isLink" target="_blank"></a>
      <input v-model.lazy="cliente[clienteData].value" value="2021-04-16" class="update" :type="inputtype!=null?inputtype:'text'" />
      <a @click.prevent="$emit('editDataCliente', [cliente, clienteData])" class="get edit-link" href="#"><i class="fas fa-pencil-alt"></i></a>
      <a @click.prevent="$emit('editDataClienteConcluir', [cliente, clienteData])" class="update edit-link" href="#"><i class="fas fa-check"></i></a>
    </div>
</template>

<script>
export default {
  props: {
    cliente: { type: Object, required: true },
    clienteData: { type: String, required: true },
    inputtype: { type: String, required: false },
    isLink: { type: String, required: false }
  },
  computed: {
    computedContent: function () {
      return this.cliente[this.clienteData].show ? this.cliente[this.clienteData].show : this.cliente[this.clienteData].value
    }
  },
};
</script>


<style scoped>
div.dadoTexto{
    position: relative;
    padding-right: 32px;
    min-height: 20px;
    min-width: 20px;
  }

  .edit-link{
    color: rgb(108, 25, 185);
    position: absolute;
    right: 4px;
    top: 0px;
    display: none;
  }
  div.dadoTexto:hover > .edit-link{
    display: block
  }

  .update{
    display: none !important;
  }
  .edit > .get{
    display: none !important;
  }
  .edit > .update{
    display: inline !important;
  }
</style>