<template>
  <div class="container">
    
    <div class="mt-4 pb-5">
        <div>
        <router-link :to="{name: 'home'}" class="">Home</router-link>
        </div>
        <h2 v-if="singleCliente_.espaco != undefined">{{ singleCliente_.espaco.value }}</h2>
        <div class="row pt-4">
            <div class="col-12 col-lg-6">
                <div class="mb-2">
                    <strong>
                        Espaço:
                    </strong>
                    <span>
                        <DadoTexto v-if="singleCliente_.espaco != undefined" class="d-inline-block p-icon" :cliente="singleCliente_" :clienteData="'espaco'" @editDataCliente="editDataCliente" @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Cliente:
                    </strong>
                    <span>
                        <DadoTexto v-if="singleCliente_.nome != undefined" class="d-inline-block p-icon" :cliente="singleCliente_" :clienteData="'nome'" @editDataCliente="editDataCliente" @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        E-mail:
                    </strong>
                    <span>
                        <DadoTexto v-if="singleCliente_.mail != undefined" class="d-inline-block p-icon" :cliente="singleCliente_" :clienteData="'mail'" @editDataCliente="editDataCliente" @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Username:
                    </strong>
                    <span>
                        <DadoTexto v-if="singleCliente_.username != undefined" class="d-inline-block p-icon" :cliente="singleCliente_" :clienteData="'username'" @editDataCliente="editDataCliente" @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Registro:
                    </strong>
                    <span v-if="singleCliente_.registro != undefined">
                        {{ singleCliente_.registro }}
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        WP ID: 
                    </strong>
                    <span>
                        <a v-if="singleCliente_.id != undefined" :href="`https://coworkingbrasil.org/wp-admin/user-edit.php?user_id=${singleCliente_.id}`" target="_blank">{{ singleCliente_.id }}</a>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Limite de espaços:
                    </strong>
                    <span>
                        <DadoTexto v-if="singleCliente_.limite_espacos != undefined" inputtype="number" class="d-inline-block p-icon" :cliente="singleCliente_" :clienteData="'limite_espacos'" @editDataCliente="editDataCliente" @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                
            </div>
            <div class="col-12 col-lg-6">
                <div class="mb-2">
                    <strong>
                        Plano:
                    </strong>
                    <span>
                        <DadoSelect v-if="singleCliente_.plano != undefined" class="d-inline-block p-icon"
                            :cliente="singleCliente_"
                            :clienteData="'plano'"
                            @editDataCliente="editDataCliente"
                            @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Status:
                    </strong>
                    <span>
                        <DadoSelect v-if="singleCliente_.status != undefined" class="d-inline-block p-icon"
                            :cliente="singleCliente_"
                            :clienteData="'status'"
                            @editDataCliente="editDataCliente"
                            @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Gateway:
                    </strong>
                    <span>
                        <DadoSelect v-if="singleCliente_.gateway != undefined" class="d-inline-block p-icon"
                            :cliente="singleCliente_"
                            :clienteData="'gateway'"
                            @editDataCliente="editDataCliente"
                            @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        Renovação:
                    </strong>
                    <span>
                        <DadoSelect v-if="singleCliente_.renovacao != undefined" class="d-inline-block p-icon"
                            :cliente="singleCliente_"
                            :clienteData="'renovacao'"
                            @editDataCliente="editDataCliente"
                            @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2" v-if="singleCliente_.gateway != undefined && singleCliente_.gateway.value == 'boleto' || true">
                    <strong>
                        Data de renovação:
                    </strong>
                    <DadoTexto class="d-inline-block p-icon"
                    :cliente="singleCliente_"
                    :clienteData="'vencimento'"
                    :inputtype="'date'"
                    @editDataCliente="editDataCliente"
                    @editDataClienteConcluir="editDataClienteConcluir"/>
                </div>
                <div class="mb-2">
                    <strong>
                        Stripe ID:
                    </strong>
                    <span v-if="singleCliente_.stripe_id != undefined">
                        <DadoTexto class="d-inline-block p-icon"
                        :cliente="singleCliente_"
                        :clienteData="'stripe_id'"
                        :isLink="`https://dashboard.stripe.com/customers/${singleCliente_.stripe_id.value}`"
                        @editDataCliente="editDataCliente"
                        @editDataClienteConcluir="editDataClienteConcluir"/>
                    </span>
                </div>
                <div class="mb-2">
                    <strong>
                        HelpScout:
                    </strong>
                    <span>
                       <a v-if="singleCliente_.mail != undefined" target="_blank" :href="`https://secure.helpscout.net/search/?query=${singleCliente_.mail.value}`">Checar Suporte</a>
                    </span>
                </div>
                
            </div>
        </div>
    </div>

    <div v-if="singleCliente_.espacos != undefined && singleCliente_.espacos.length != 0">
    <div class="mt-4 pb-5">
        <h2>Espaços publicados:</h2>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID</th>
                    <th scope="col" style="min-width: 110px;">Tipo</th>
                    <th scope="col">Espaço</th>
                    <th scope="col">Bairro</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Contato</th>
                    <th scope="col" style="min-width: 200px;">E-mail</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Site</th>
                    <th scope="col">Plan</th>
                    <th scope="col" title="24h">
                        <img draggable="false" role="img" class="emoji" alt="🌙" src="https://s.w.org/images/core/emoji/13.0.0/svg/1f319.svg">
                    </th>
                    <th scope="col" title="Acessível para cadeirante">
                        <img draggable="false" role="img" class="emoji" alt="♿" src="https://s.w.org/images/core/emoji/13.0.0/svg/267f.svg">
                    </th>
                    <th scope="col" title="Estacionamento">
                        <img draggable="false" role="img" class="emoji" alt="🚗" src="https://s.w.org/images/core/emoji/13.0.0/svg/1f697.svg">
                    </th>
                    </tr>
                </thead>
                <tbody v-if="singleCliente_.espacos != undefined">
                    <Espacos v-for="(espaco, index) in singleCliente_.espacos"
                        :espaco="espaco"                    
                        :key="espaco.id"
                        :index="index"
                        @editDataCliente="editDataCliente"/>

                </tbody>
            </table>
        </div>
    </div>
    </div>
    <div class="d-flex justify-content-center">
      <div :class="objLoading[1] ? 'loading' : ''" class="loadind-espacos spinner-border">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-if="singleCliente_.invoices != undefined && singleCliente_.invoices.length != 0">
    <div class="mt-4 pb-5">
        <h2>Invoices:</h2>
        <div class="table-responsive">
            <table class="table table-responsive table-striped">
                <thead>
                    <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Assinatura</th>
                    <th style="min-width: 120px;" scope="col">Valor a pagar</th>
                    <th scope="col">Identificador</th>
                    <th scope="col">Data</th>
                    </tr>
                </thead>
                <tbody v-if="singleCliente_.invoice != undefined">
                    <Invoice v-for="(espaco, index) in singleCliente_.invoices"
                        :invoice="espaco"                    
                        :key="espaco.id"
                        :index="index"
                        @editDataCliente="editDataCliente"/>
                </tbody>
            </table>
        </div>
    </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
      <div :class="objLoading[3] ? 'loading' : ''" class="loadind-leads spinner-border">
        <span class="sr-only"></span>
      </div>
    </div> -->

    <div v-if="singleCliente_.leads != undefined && singleCliente_.leads.length != 0">
    <div class="mt-4 pb-5">
        <h2>Leads:</h2>
        <div class="table-responsive">
            <table class="table table-responsive table-striped">
                <thead>
                    <tr>
                    <th style="min-width: 200px;"  scope="col">Nome</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Telefone</th>
                    <th style="min-width: 200px;" scope="col">Mensagem</th>
                    <th scope="col">Unidade</th>
                    <th scope="col">Data</th>
                    </tr>
                </thead>
                <tbody v-if="singleCliente_.leads != undefined">
                    <Lead  v-for="(espaco, index) in singleCliente_.leads"
                        :lead="espaco"                    
                        :key="espaco.id"
                        :index="index"
                        @editDataCliente="editDataCliente"/>
                </tbody>
            </table>
        </div>
    </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
      <div :class="objLoading[2] ? 'loading' : ''" class="loadind-leads spinner-border">
        <span class="sr-only"></span>
      </div>
    </div> -->

    <div class="confirm-shadow" v-if="getConfirmEdit[0]">
        <div class="confirm-box">
            <div class="confirm-title">Tem certeza que deseja alterar o Stripe ID <span class="confirm-data">{{getConfirmEdit[1][0][getConfirmEdit[1][1]].confirmedit}}</span> por <span class="confirm-data">{{getConfirmEdit[1][0][getConfirmEdit[1][1]].value}}</span>?</div>
            <div class="confirm-buttons">
                <a v-on:click.prevent="editDataClienteCancelar" class="confirm-button confirm-button-no" href="#">Não</a>
                <a v-on:click.prevent="editDataClienteConcluir" class="confirm-button confirm-button-yes" href="#">Sim</a>
            </div>
            <div class="confirm-label">Esse dado é importante. Cuidado para não perdê-lo.</div>
        </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Espacos from "@/components/Espacos";
import Invoice from "@/components/Invoice";
import Lead from "@/components/Lead";
import DadoTexto from "@/components/DadoTexto";
import DadoSelect from "@/components/DadoSelect";

export default {
  name: "app",
  components: { Espacos, Invoice, Lead, DadoTexto, DadoSelect },
  computed: {
    ...mapGetters(["todosClientes", "objLoading", "getConfirmEdit"]),
    singleCliente_() {

        console.log(typeof this.todosClientes);

        const index = this.todosClientes.findIndex(item => item.id == this.$route.params.id);


        if (index > -1) {
            return this.todosClientes[index]
        }


        return {}
    }

    //

  },
  methods: {
    ...mapActions(["editDataCliente", "editDataClienteConcluir", "load_cliente", "editDataClienteCancelar"]),
    editDataClienteConcluir_(data){
        this.editDataClienteConcluir([data, this.$router])
    }
  },
  mounted () {
    this.load_cliente([this.$route.params.id, this.$router])
  },
};
</script>

<style scoped> 
    div.p-icon{
        padding-right: 30px !important;
    }
    .loadind-espacos{
        margin-top: 12px;
        height: 25px;
        width: 25px;
        font-size: 12px;
        display: none;
    }

    .loading.loadind-espacos{
        display: block;
    }
    .loadind-leads{
        margin-top: 12px;
        height: 25px;
        width: 25px;
        font-size: 12px;
        display: none;
    }

    .loading.loadind-leads{
        display: block;
    }

    .emoji{
        display: inline !important;
        border: none !important;
        box-shadow: none !important;
        height: 1em !important;
        width: 1em !important;
        margin: 0 .07em !important;
        vertical-align: -0.1em !important;
        background: none !important;
        padding: 0 !important;
    }

    .confirm-shadow{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
    }
    .confirm-box{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 32px;
        max-width: 500px;
    }
    .confirm-title{
        margin-bottom: 32px;
    }
    .confirm-data{
        font-weight: 700;
    }
    .confirm-buttons{
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
    }
    .confirm-button{
        margin: 0px 12px;
        color: #fff;
        text-decoration: none;
        padding: 4px 16px;
        border-radius: 4px;
    } 
    .confirm-button-no{
        background-color:royalblue;
    }
    .confirm-button-yes{
        background-color:darkred ;
    }
    .confirm-label{
        font-style: italic;
        text-align: center;
    }

</style>